* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.modal-75w {
  max-width: 75%;
}
.uploadBtn_Box {
  box-shadow: rgb(243 243 243) 0px 0px 0px 0px, rgb(0 0 0 / 10%) 0px 2px 2px 0px,
    rgb(0 0 0 / 6%) 0px 1px 5px 0px;
}
.uploadBtn_Box .btn-link.activeBtn {
  background-color: #416eb2;
  color: white;
}
.uploadContainer,
.uploadBtn_Box {
  min-height: 600px;
}
.row {
  margin-right: 0px;
  margin-left: 0px;
}
.imgGallery {
  max-height: 600px;
  overflow-y: auto;
}
.imgGallery .row .col-md-4:hover {
  background-color: #e6e6e6;
}
.imgGallery .gallery_Img {
  width: 100%;
  margin: auto;
  object-fit: contain;
}
.selectedImg {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.spinnerBox {
  height: 500px;
}
.btn:focus {
  box-shadow: none;
}
.editorModal .modal-dialog {
  max-width: 992px;
  width: 100%;
  margin: auto;
}
.upload-imgBox img {
  max-height: 400px;
  max-width: 400px;
  object-fit: contain;
}
.uploadImageModal .modal-dialog {
  max-width: 992px;
}
.cross {
  cursor: pointer;
}
.modal-backdrop.show {
  z-index: 1050;
}
@media (max-width: 592px) {
  .uploadContainer,
  .uploadBtn_Box {
    min-height: auto;
    display: flex;
    margin-top: 20px;
  }
  .insertImg h2 {
    margin-top: 20px;
    font-size: 16px;
  }
}
